@import "src/modules/common/styles/utils/variables";

.cardBody {
  width: 465px;
  height: 230px;
  display: flex;
  justify-content: center;
  &_title {
    padding: 20px 33px 20px 0;
    border-right: 1px solid $modal-border-2;
  }
  &_about {
    padding: 40px 30px 15px 50px;
  }
}

.logo {
  background: url('./images/logo.svg') no-repeat center;
  width: 61px;
  height: 54px;
}

.title {
  font-family: "Proxima Nova SBold", sans-serif;
  display: block;
  font-style: normal;
  font-weight: 700;
  font-size: 38px;
  line-height: 42px;
  color: $dark;
}

.about {
  font-family: "Proxima Nova SBold", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  color: $gray-title;
}

.contact {
  display: flex;
  justify-content: space-between;
  &_description {
    margin: 0;
    font-family: "Proxima Nova SBold", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 13px;
    a {
      color: $contact-link;
    }
  }
  &_icon {
    padding-right: 10px;
  }
}



