.popup {
  width: 100%;
  max-width: 450px;
  //max-width: 90vw;
}

.header {
  min-height: 60px;
  border-bottom: 1px solid var(--modal-border);
  padding: 0 21px;
  font-size: 18px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &-confirm {
    --main-color: var(--modal-confirm-color);
  }

  &-destructive {
    --main-color: var(--modal-destructive-color);
  }

  &-success {
    --main-color: var(--modal-success-color);
  }

  &-warning {
    --main-color: var(--modal-warning-color);
  }
}

.title {
  display: flex;
  align-items: flex-start;
  color: var(--main-color);
  padding-right: 16px;
}

.icon {
  width: 18px;
  height: 1em;
  margin-right: 14px;
}

.content {
  padding: 16px;
  font-size: 16px;
  color: #3f3f3f;
}

.buttons {
  display: flex;
  padding: 16px 20px;
  justify-content: flex-end;
  & > * {
    margin-left: 10px;
    &:first-child {
      margin-left: 0;
    }
  }
}
.warning {
  composes: btn_warning from '/src/liw-common-fe/@liw-components/Button/Button.module.scss';
}
.destructive {
  composes: btn_danger from '/src/liw-common-fe/@liw-components/Button/Button.module.scss';
}
.success {
  composes: btn_success from '/src/liw-common-fe/@liw-components/Button/Button.module.scss';
}
.confirm {
  composes: btn_primary from '/src/liw-common-fe/@liw-components/Button/Button.module.scss';
}

.spinner {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
