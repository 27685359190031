@import '../utils/variables';

// GLOBAL ICON SIZE
i {
  font-size: 16px !important;
}
.liw-color {
  color: $grow-green;
}
.bold {
  font-weight: 600;
}
.gray {
  color: $lt-black-3;
}
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}
