@import '../utils/variables';

$module-gap: 25px;
.landing-page {
  .module {
    padding-top: $module-gap;
    &::after {
      content: '';
      display: block;
      margin: 0 auto;
      padding-top: $module-gap;
      width: calc(100% - 20px);
      border-bottom: 1px solid #c1c4cc;
    }

    h3 {
      margin-top: 0;
      margin-bottom: 10px;
    }
    h4 {
      font-family: 'Proxima Nova';
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .buttons {
      a,
      button:not(:last-of-type) {
        margin-right: 10px;
      }
    }
  }
  .future-workspaces {
    margin-top: 10px;
    .future-workspace {
      &__content {
        border: 1px solid #c1c4cc;
        padding: 10px;
      }
    }
  }
}

@media (max-width: 991px) {
  .future-workspace {
    &:not(:first-of-type) {
      margin-top: 10px;
    }
  }
}
