.content {
  cursor: help;
}

.block {
  display: block;
}

.tooltip {
  font-size: 13px;
  font-weight: normal;
  max-width: 320px;
  text-align: left;
  line-height: 1.18;
  white-space: normal;
}


