@import '../utils/variables';

// absolutely center an element in a relatively positioned element
.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fixed-center {
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
}

// center rows
.centered-row {
  display: flex;
  justify-content: center;
}

// Widths
.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}
.w-70 {
  width: 70%;
}

.scrim-container {
  position: fixed;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: $z-index-1;
  background-color: $scrim-container;
}

.inline-block {
  display: inline-block;
}

:root {
  --grow-green: #{$grow-green};
  --accent-primary: #{$accent-primary};
  --accent-primary-hover: #{$accent-primary-hover};
  --accent-primary-dark: #{$accent-primary-dark};
  --accent-primary-dark-2: #{$accent-primary-dark-2};
  --accent-link: #{$accent-link};
  --accent-primary-lt: #{$accent-primary-lt};
  --accent-secondary: #{$accent-secondary};
  --white: #{$white};
  --gray: #{$gray};
  --lt-gray: #{$lt-gray};
  --lt-gray-2: #{$lt-gray-2};
  --lt-gray-3: #{$lt-gray-3};
  --lt-gray-4: #{$lt-gray-4};
  --md-gray: #{$md-gray};
  --md-gray-2: #{$md-gray-2};
  --md-gray-3: #{$md-gray-3};
  --drk-gray-1: #{$drk-gray-1};
  --lt-black: #{$lt-black};
  --gray-border: #{$gray-border};
  --small-thumbnail-width: 85px;
  --small-thumbnail-height: 85px;
  --card-radius: #{$card-border-radius}
}
