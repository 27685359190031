.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  text-transform: none;
}

.icon {
  padding: 1px;
  flex-shrink: 0;
}

.iconOnly {
  padding: 3px;
}

.iconLeft {
  flex-direction: row-reverse;
}

.spinnerWrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 4px 0 0;
}

.btn {
  --disabled-bg: #E5E5E5;
  --disabled-color: #929292;
  --disabled-border: #CBCBCB;
  border: 0 none;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  font-family: Proxima Nova, "Proxima Nova", sans-serif;
  &:focus-visible {
    outline: none;
  }
  /** BY SIZE */
  &_large {
    height: 50px;
    line-height: 50px;
    padding: 0 25px;
    font-size: 13px;
  }
  /** BY COLOR */
  &_primary {
    color: #fff;
    --main-color: #0032A0;
    --secondary-color: #002880;
    --third-color: #D8E4FE;
  }
  &_black {
    // color: #000;
    --main-color: #000;
  }
  &_white {
    color: #000;
    --main-color: #fff;
    --secondary-color: #E5E5E5;
    --third-color: #F4F4F4;
  }
  &_info {
    color: #fff;
    --main-color: var(--info);
    --secondary-color: #000;
    --third-color: #ECECEC;
  }
  &_success {
    color: #fff;
    --main-color: var(--success);
    --secondary-color: #006B35;
    --third-color: #E6F3EC;
  }
  &_warning {
    color: #fff;
    --main-color: var(--warning);
    --secondary-color: #AF6209;
    --third-color: #FBF2E7;
  }
  &_danger {
    color: #fff;
    --main-color: var(--danger);
    --secondary-color: #AC2531;
    --third-color: #FBEAEC;
  }
  /** BY STYLE */
  &_solid {
    background-color: var(--main-color);
    border: 1px solid var(--main-color);
    &:hover, &:active, &:focus {
      background-color: var(--secondary-color);
      border-color: var(--secondary-color);
      text-decoration: none;
      color: #fff;
    }
    &_disabled, &:disabled {
      background-color: var(--disabled-bg);
      color: var(--disabled-color);
      border-color: var(--disabled-border);
    }
  }
  &_outline {
    border: 1px solid var(--main-color);
    color: var(--main-color);
    background: #fff;
    &:hover, &:active, &:focus {
      background-color: var(--third-color);
      text-decoration: none;
    }
    &_disabled, &:disabled {
      background-color: #fff;
      color: var(--disabled-color);
      border-color: var(--disabled-border);
    }
  }
  &_link {
    color: var(--main-color);
    background: transparent;
    &:hover, &:active, &:focus {
      text-decoration: underline;
    }
    &_disabled, &:disabled {
      color: var(--disabled-color);
      text-decoration: none;
    }
  }
}