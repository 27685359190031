.select {
  background-color: #fff;
  padding: 5px 6px;
}

.placeholder {
  color: #c1c4cc;
  font-style: italic;

  option {
    color: #000;
    font-style: normal;
  }
}
