.inputLabel {
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.iconInLabel {
  display: inline-flex;

  text-align: center;
  color: #0061AB;
}

.warningIcon {
  display: flex;
  align-items: center;
  font-size: 13px;
  text-align: center;
  color: #e8be04 !important
}

.errorIcon {
  font-size: 13px;
  text-align: center;
  color: #e8be04 !important
}

.labelSpinner {
  display: inline-flex;
  margin-left: 5px;
}
