.container {
  display: flex;
  gap: 15px;
}

.icon {
}

.content {
  font-size: 16px;
  line-height: 16px;

}

.title, .subtitle {
  font-family: "Proxima Nova SBold", sans-serif;
}

.title {
  color: #cbcbcb;

}

.subtitle {
  color: #000;

}

.description {
  margin-top: 9px;
  color: #3f3f3f;
}
