.icon {
  display: inline-flex;
  height: 1em;
  width: 1em;
  line-height: 1.4;

  svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
  }
}
