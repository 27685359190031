@import "src/modules/common/styles/utils/variables";

.btn {
  width: 87px;
  height: 50px;
  margin: 20px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 17px 25px;
  gap: 10px;
  background: $white;
  color: $second-black;
  border: 1px solid #383838;
  border-radius: 6px;
}