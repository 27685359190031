@import 'src/modules/common/styles/utils/variables';

.selectContent {
    width: 160px;
  }
  .buttonContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
    .buttonCancel, .buttonSave {
      padding: 10px 15px;
      font-weight: 600;
      border: 1px solid $second-black;
      &:active, &:focus {
        color: $black;
      }
    }
    .buttonCancel {
      background-color: $white;
      color: $second-black;
      &:active, &:focus {
        background-color: $white;
        color: $black;
      }
    }
    .buttonSave {
      background-color: $second-black;
      color: $white;
      &:active, &:focus {
        background-color: $black;
        color: $white;
      }
    }
  }