.card {
  border: none;
  border-radius: 0;
}

// Trigger theme hover behavior on <p> and <i> elements in dropdown elements
.btn-dropdown {
  text-transform: none;
  &:hover {
    .btn-dropdown-element {
      color: #0061ab;
    }
  }
}
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.col {
  max-width: 100%;
  flex: 1 1 auto;
}

// remove gray borders from some wells
.well-no-border {
  border: none;
}

// center rows
.centered-row {
  display: flex;
  justify-content: center;
}

.w-100 {
  width: 100%;
}

// remove borders from readonly form fields
// (not available in current theme version)
.plaintext {
  border: none;
}

@media (min-width: 1400px) {
  .container {
    width: 1200px;
  }
}
