.burger {
  background: none;
  border: 0 none;
  padding: 0;
  margin: 0;
  width: 40px;
  height: 40px;
  color: #3F3F3F;
  font-family: "Proxima Nova", sans-serif;
  line-height: 40px;
  &:hover {
    background-color: #d9d9d9;
    color: #3F3F3F;
  }
  i {
    vertical-align: middle;
  }
}
.navbar {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
}
.right {
  display: block;
}
.logo {
  display: flex;
  align-items: center;
  font-family: "Proxima Nova SBold", sans-serif;
  &::before {
    content: '';
    display: block;
    background: url('./images/logo-bw.svg') scroll no-repeat center left;
    width: 40px;
    height: 35px;
    margin-right: 5px;
  }
  p {
    margin: 0;
    padding: 0;
  }
}