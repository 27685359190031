@import "src/modules/common/styles/utils/variables";

.wrapper {
  display: flex;
  height: 60px;
}

.stripe {
  position: absolute;
  top: 0;
  left: 0;
  width: 19px;
  height: 60px;
}

.link {
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover, &:active {
    text-decoration: none;
  }
}

.logo {
  mask: url('../images/logo-bw.svg') no-repeat center;
  width: 30px;
  height: 30px;
  margin-left: 100px;
  background-color: $black;
}

.stripe, .logo {
  &_dev {
    background-color: $dev-orange;
  }

  &_qa {
    background-color: $qa-purple;
  }

  &_uat, &_muat {
    background-color: $uat-blue;
  }

  &_staging {
    background-color: $staging-pink;
  }
}

.grow {
  font-size: 13px;
  line-height: 14px;
  margin-left: 12px;
  color: $black;

  &_dev {
    color: $dev-orange;
  }

  &_qa {
    color: $qa-purple;
  }

  &_uat, &_muat {
    color: $uat-blue;
  }

  &_staging {
    color: $staging-pink;
  }
}