@import '../utils/variables';

.footer-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: flex-end;

  .navbar-footer {
    text-align: left;

    width: 100%;
  }

  .navbar-text {
    p {
      &:first-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.navbar .container .navbar-brand {
  margin-left: $logo-space;
}
