.wrapper {
  max-width: 1200px;
  margin: 0 auto 60px auto;
  padding: 0 10px 0;
}

.container {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 2fr 2fr 1fr;
  // grid-template-rows: repeat(4, minmax(180px, auto));
  padding-top: 40px;
  // grid-template-areas:
  // "item-a item-a item-b"
  // "item-c item-d item-d"
  // "item-c item-e item-e"
  // "item-f item-f item-g";
}