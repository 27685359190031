@import '../utils/variables';

.login-container {
  margin-top: 18vh;

  .main-copy {
    margin-bottom: $space-2;
  }

  .btn {
    margin-bottom: $space-2;
  }
}
