.container {
  flex-grow: 1;
  padding: 19px 10px 22px;
}
.list {
  padding: 0;
}
.item {
  list-style-type: none;
  margin-top: 12px;
}

.link {
  border-radius: 6px;
  padding: 9px 20px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  &:first-child {
    margin-top: 0;
  }
}

.status {
  color: #CBCBCB;
  display: flex;
  align-items: center;
  &::after {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid #CBCBCB;
    display: block;
    margin-left: 5px;
  }
  &.online::after {
    background-color: #008642;
  }
  &.planned_maintenance::after {
    background-color: #0032A0;
  }
  &.under_construction::after {
    background-color: #B45400;
  }
}
.content {
  color: #646464;
  padding-left: 49px;
  margin-top: 5px;
}