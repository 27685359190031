.container {
  background: var(--lt-gray-3);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
  color: var(--lt-black);
  font-family: "Proxima Nova", sans-serif;
  margin: 0;
  padding: 30px 30px 0;
}
