.subheader {
  display: flex;
  background-color: #fff;
  padding-top: 40px;
  margin-bottom: 40px;
  z-index: 4;
  min-height: 100px;
  max-height: 140px;
  transition: all ease-in-out .3s;
}

.shrink {
  padding-top: 20px;
}

.sticky {
  position: sticky;
  top: 60px;
}

.infoBlock {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.rightSideBlock {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
}

.paginationBlock {
  width: 70%;
}

.location {
  font-family: 'Proxima Nova SBold', serif;
  font-size: 32px;
  line-height: 34px;
  color: #cbcbcb;
  font-weight: bold;
}

.mainInfo {
  font-family: 'Proxima Nova Sbold';
  font-size: 32px;
  line-height: 34px;
}

.additionalInfo {
  font-size: 11px;
  line-height: 13px;
  margin-top: 10px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
}

.hidden {
  opacity: 0;
  margin-bottom: 5px;
  margin-top: -5px;
}

.divider {
  opacity: 0;
  width: 100vw;
  position: fixed;
  top: 161px;
  left: calc(-50vw + 50%);
  margin-top: -1px;
  border-bottom: 1px solid #e5e5e5;
  z-index: 4;
}

.dividerShown {
  opacity: 1;
  transition: all 1s ease-in-out;
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
}